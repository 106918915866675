<template lang="html" src="./pageQuotes.template.vue"></template>

<style lang="scss" src="./pageQuotes.scss"></style>

<script>
import { ApiErrorParser, DataTableFilter, CustomFilterForSearchDatatable } from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./pageQuotes.i18n');

export default {
  name: 'PageQuotes',
  components: { DataTableFilter },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      headers: [],
      quotes: [],
      loading: false,
      search: '',
      loadingTable: false,
      statusFilter: [],
      typeFilter: [],
      filterObject: {},
    };
  },
  created() {
    this.getAllQuotes();
  },
  mounted() {
    this.filterObject = {
      status: [
        {
          name: this.$t('QuoteLocked'),
          value: 'LOCKED', 
          iconColor: 'info',
          icon: 'fas fa-user-cog',
          number: 0,
          get selected() {
            return this.number > 0;
          }
        },
        {
          name: this.$t('QuoteReady'),
          value: 'READY', 
          iconColor: 'warning',
          icon: 'fas fa-clock',
          number: 0,
          get selected() {
            return this.number > 0;
          }
        },
        {
          name: this.$t('QuoteDeclined'),
          value: 'DECLINED', 
          iconColor: 'error',
          icon: 'fas fa-times-circle',
          number: 0,
        },
        {
          name: this.$t('QuoteAccepted'),
          value: 'ACCEPTED', 
          iconColor: 'success',
          icon: 'fas fa-check-circle',
          number: 0,
        }
      ],
      type: [
        {
          name: this.$t('BCM'),
          value: 'public', 
          img: 'logo_cut.png',
          number: 0,
          selected: true
        },
        {
          name: this.$t('PrivateSupplier'),
          value: 'private', 
          iconColor: 'privateSupplier',
          icon: 'fas fa-warehouse',
          number: 0,
        }
      ]
    };
    this.headers = [
      {
        text: this.$t('Status'),
        value: 'status',
        width: '100px',
        align: 'center',
        filter: (value) => {
          if (this.statusFilter.length === 0) return true;
          if (this.statusFilter.includes(value) || (value === 'CLOSED' && this.statusFilter.includes('ACCEPTED'))) return true;
        }
      },
      {
        text: this.$t('Type'),
        value: 'public',
        width: '100px',
        align: 'center',
        sortable: false,
        filter: (value) => {
          if (this.typeFilter.length === 0) return true;
          if ((value && this.typeFilter.includes('public')) || (!value && this.typeFilter.includes('private')) ) return true;
        },
      },
      {
        text: this.$t('QuoteID'),
        value: 'quoteNumber',
      },
      {
        text: this.$t('PurchaseOrderNumber'),
        value: 'purchaseOrderNumber',
      },
      {
        text: this.$t('Brand'),
        value: 'brand.name',
      },
      {
        text: this.$t('User'),
        value: 'user',
        sort: this.sortUserFn
      },
      {
        text: this.$t('priceVAT'),
        value: 'selling.totalAmount',
        align: 'right',
      },
      { text: this.$t('Created'), value: 'created', align: 'center' },
      { text: this.$t('ExpirationDate'), value: 'expirationDate', align: 'center' },
      {
        text: this.$t('Action'),
        value: 'action',
        align: 'center',
        sortable: false,
      },
    ];
  },
  methods: {
    sortUserFn(u1, u2) {
      const fullName1 = u1.lastname + '.' + u1.firstname;
      const fullName2 = u2.lastname + '.' + u2.firstname;
      if(fullName1 === fullName2) {
        return 0;
      }
      return fullName1 < fullName2 ? 1 : -1;
    },
    customFilter(value, search, item) {
      if (!search) return true;
      return CustomFilterForSearchDatatable.customFilter(value, search, this);
    },
    getAllQuotes() {
      this.loadingTable = true;
      this.loading = true;
      /**
       * GET ALL QUOTES
       */
      this.$apiInstance
        .getAdminQuotes()
        .then(
          (data) => {
            this.quotes = data;
            /**
             * Reset Filter Object
             */
            for (const key in this.filterObject) {
              for (const obj of this.filterObject[key]) {
                obj.number = 0;
              }
            }
            this.quotes.forEach(quote => {
              this.filterObject.status.find(x => x.value === quote.status || (x.value === 'ACCEPTED' && quote.status === 'CLOSED')).number++;
              this.filterObject.type.find(x => (x.value === 'public' && quote.public) || (x.value === 'private' && !quote.public)).number++;
            });
          },
          (error) => {
            /**
             * ERROR GET ALL QUOTES
             */
            ApiErrorParser.parse(error);
          }
        )
        .finally(() => {
          this.loadingTable = false;
          this.loading = false;
        });
    },
    updateFilter(updatedFilter) {
      this.statusFilter = updatedFilter.status;
      if(updatedFilter.type) {
        this.typeFilter = updatedFilter.type;
      }
    },
    goToPageItem(item) {
      this.$router.push({name: 'Quote', params: { quoteUUID: `${item._id}`}});
    },
  },
};
</script>
